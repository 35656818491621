.calendlyWidget {
  min-width: 320px;

  /* height: 1112px; */
  height: calc(100vh - 120px);
}

@media only screen and (min-width: 769px) {
  .calendlyWidget {
    height: 1170px;
  }
}

@media only screen and (min-width: 1025px) {
  .calendlyWidget {
    height: 700px;
  }
}
